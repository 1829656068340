import { put, del } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';

/**
 * Saves and updates the member in the database
 * @param {string} appId application id
 * @param {object} mem member object from state
 * @returns {Promise} http message response promise
 */
export const saveMember = async (appId, mem) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await put({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/member/${mem.userId}`,
            options: {
                headers: {
                    Authorization: token
                },
                body: {
                    permissionLevel: mem.permissionLevel,
                    isOwner: mem.permissionLevel === 7 ? true : false
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Sends the member request and saves to the database
 * @param {string} appId application id
 * @param {object} memReq memberRequest object from state
 * @returns {Promise} http message response promise
 */
export const initiateMemberRequest = async (appId, memReq) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await put({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/member/request`,
            options: {
                headers: {
                    Authorization: token
                },
                body: {
                    email: memReq.email,
                    permissionLevel: memReq.permissionLevel,
                    isOwner: memReq.permissionLevel === 7 ? true : false
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Removes the request to add a new member from the application and updates the database
 * @param {string} appId application id
 * @param {string} reqId new member request id
 * @returns {Promise} http message response promise
 */
export const acceptMemberRequest = async (appId, reqId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await put({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/member/request/${reqId}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Removes the request to add a new member from the application and updates the database
 * @param {string} appId application id
 * @param {string} reqId new member request id
 * @returns {Promise} http message response promise
 */
export const cancelMemberRequest = async (appId, reqId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { statusCode } = await del({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/member/request/${reqId}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return statusCode;
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Remove the user from the application and updates the database
 * @param {string} appId application id
 * @param {string} memId user id of member to remove
 * @returns {Promise} http message response promise
 */
export const removeMember = async (appId, memId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { statusCode } = await del({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/member/${memId}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return statusCode;
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};
