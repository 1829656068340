import { get, put, post, del } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';

/**
 * Gets a list of applications the current user has access to
 * @param {string} [filter] app filter
 * @returns {Promise} promise which resolves a list of application objects
 */
export const getApplications = async (filter) => {
    try {
        let url = '/app/list';

        if (filter) {
            url = url + '?operator=contains&filterBy=name&filterValue=' + filter;
        }

        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await get({
            apiName: 'stitchz-web-api',
            path: url,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Save the application to the database
 * @typedef {{
 * applicationId: number
 * name: string
 * description: string
 * url: string
 * privacyUrl: string
 * subscriptionType: number
 * termsAccepted: boolean
 * }} ApplicationModel
 * @param {ApplicationModel} app application to create
 * @returns {Promise} promise which resolves to new or updated application object
 */
export const saveApplication = async (app) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        if (app.applicationId) {
            const { body } = await put({
                apiName: 'stitchz-web-api',
                path: `/app/${app.applicationId}`,
                options: {
                    headers: {
                        Authorization: token
                    },
                    body: app
                }
            }).response;

            return await body.json();
        } else {
            const { body } = await post({
                apiName: 'stitchz-web-api',
                path: '/app/create',
                options: {
                    headers: {
                        Authorization: token
                    },
                    body: app
                }
            }).response;

            return await body.json();
        }
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Get the application's getails
 * @param {string} appId application id
 * @returns {Promise} promise with application info
 */
export const getApplicationDetails = async (appId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await get({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Removes the current application from the database
 * @param {string} appId application id
 * @returns {Promise} http message response promise
 */
export const deleteApplication = async (appId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { statusCode } = await del({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return statusCode;
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Gets a list of applications the current user has access to
 * @param {string} filter app filter
 * @returns {Promise} promise which resolves a list of application objects
 */
export const getConfigApplications = async (filter) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        let url = '/config/apps';

        if (filter) {
            url = url + '?filter=name&value=' + filter;
        }

        const { body } = await get({
            apiName: 'stitchz-web-api',
            path: url,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Get the application's getails
 * @param {string} appId application id
 * @returns {Promise} promise with application info
 */
export const getConfigApplicationDetails = async (appId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await get({
            apiName: 'stitchz-web-api',
            path: `/config/app/${appId}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};
