import { isValid } from 'date-fns';

import { parse } from 'date-fns';
import { isAfter } from 'date-fns';
import { setHours } from 'date-fns';
import { setMinutes } from 'date-fns';
import { addHours } from 'date-fns';
import { format } from 'date-fns';
import { parseISO } from 'date-fns';
import { isBefore } from 'date-fns';
import { fromUnixTime } from 'date-fns';
import { differenceInDays } from 'date-fns';
import { differenceInHours } from 'date-fns';
import { differenceInMinutes } from 'date-fns';
import { addDays } from 'date-fns';
import { addMonths } from 'date-fns';
import { getDaysInMonth } from 'date-fns';
import { getISODay } from 'date-fns';
import { getDay } from 'date-fns';
import { getISOWeek } from 'date-fns';
import { getWeek } from 'date-fns';
import { getMonth } from 'date-fns';
import { setDate } from 'date-fns';
import { getDate } from 'date-fns';
import { getISOWeeksInYear } from 'date-fns';
import { getUnixTime } from 'date-fns';
import { getWeekOfMonth } from 'date-fns';
import { addWeeks } from 'date-fns';
import { setISOWeek } from 'date-fns';
import { setWeek } from 'date-fns';
import { startOfISOWeek } from 'date-fns';
import { isToday } from 'date-fns';

/**
 * Checks if the date is a valid date
 * @param {Date} date date object
 * @returns {boolean} true=valid date|false=invalid date
 */
export const isValidDateTime = (date) => {
    return isValid(date) && isAfterDate(date, new Date('1/1/2000'));
};

/**
 * Checks if the given time string is in a valid HH:mm format
 * @param {string} time HH:mm formated string
 * @returns {boolean} true=valid time string|false=improperly formatted time string
 */
export const isValidTime = (time) => {
    const timeChunks = time.split(':');

    if (!timeChunks || timeChunks.length < 2) {
        return false;
    }

    try {
        const date = new Date();

        date.setHours(timeChunks[0]);
        date.setMinutes(timeChunks[1]);

        return isValidDateTime(date);
    } catch {
        return false;
    }
};

/**
 * Takes in a date string and transform into date object
 * @param {string} date date string to transform into valid date object
 * @returns {Date|void} new date object in the local time zone
 */
export const parseDateTime = (date) => {
    try {
        if (typeof date !== 'string') {
            // console.log(date);
            // console.log(date.toString());
            // console.log(date.toISOString());
            // console.log(typeof date);
            date = date.toString();
        }
        return parseISO(date);
    } catch(err) {
        console.error(err);
    }
};

/**
 * Adds hours/minutes to the given date
 * @param {string} time HH:mm formated string
 * @param {Date} [date] date object to add the hours/minutes to
 * @returns {Date} adjusted date
 */
export const addTimeToDate = (time, date) => {
    if (!time) {
        throw new Error('A properly formatted (HH:mm) time string is required!');
    } else if (!isValidTime(time)) {
        throw new Error('The given time string is not formatted properly');
    }

    const timeChunks = time.split(':'),
        hours = timeChunks[0],
        minutes = timeChunks && timeChunks.length > 1 ? timeChunks[1] : undefined;

    if (!date) {
        date = new Date();
    }

    date.setHours(hours);
    date.setMinutes(minutes);

    return date;
};

/**
 * Adds the hours/minutes to the given date
 * @param {Date} time date object with the hours/minutes to add
 * @param {Date} [date] date object to add the hours/minutes to
 * @returns {Date} adjusted date
 */
export const setDateTime = (time, date) => {
    if (typeof time === 'string') {
        time = addTimeToDate(time);
    }

    if (!date) {
        date = new Date();
    } else if (typeof date === 'string') {
        date = parse(date, 'dd-MMM-yyyy', new Date()); //new Date(date); <-- has trouble reading years when in this format 17-Sep-2020
    }

    const minutes = time.getMinutes(),
        hours = time.getHours();

    return setHours(setMinutes(date, minutes), hours);
};

/**
 * Adjust the current date to reflect the given timezone
 * @param {Date} date date object
 * @param {number} timeZoneOffset number of hours (+/-) different than GMT
 * @returns {Date} time zone adjusted date object
 */
export const addTimezoneOffset = (date, timeZoneOffset) => {
    //return addHours(date, timeZoneOffset);
    return add(date, timeZoneOffset, 'hours');
};

/**
 * Gets the local timezone } from the date object
 * @param {Date|string} date date object or ISO formatted string
 * @param {string} [givenFormat] string of unicode characters defining the date input string
 * @returns {Date} updated Date object with the adjusted time
 */
export const getLocalDateTime = (date, givenFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") => { // eslint-disable-line quotes
    const dateProvided = date;

    if (!dateProvided) {
        date = new Date();
    } else if (typeof date === 'string') {
        date = parseDateTime(date, givenFormat);
    }

    // if we don't have a valid date then set a default date
    if (!isValidDateTime(date)) {
        date = new Date();
    }

    if (!dateProvided) {
        const timeZoneOffSet = date.getTimezoneOffset() / 60, // in hours
            offSetDate = addTimezoneOffset(date, -timeZoneOffSet);

        return offSetDate;
    } else {
        return date;
    }
};

/**
 * Transform the given date object into a formatted string
 * @param {Date} date date object
 * @param {string} dateFormat format to transform the date object into
 * @returns {string} formated date string
 */
export const formatDate = (date, dateFormat) => {
    if (!date) {
        throw new Error('Date object required!');
    } else if (typeof date === 'string') {
        date = parseDateTime(date, dateFormat);
    }

    return format(date, dateFormat);
};

/**
 * Checks if the given date is before the compare to date
 * @param {Date} date the date that should be before
 * @param {Date} dateToCompare the date to compare with
 * @returns {boolean} true the date is before, false if it's after
 */
export const isBeforeDate = (date, dateToCompare) => {
    return isBefore(date, dateToCompare);
};

/**
 * Checks if the given date is after the compare to date
 * @param {Date} date the date that should be after
 * @param {Date} dateToCompare the date to compare with
 * @returns {boolean} true the date is after, false if it's before
 */
export const isAfterDate = (date, dateToCompare) => {
    if (typeof dateToCompare === 'string') {
        dateToCompare = parse(dateToCompare, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date()); // eslint-disable-line quotes
    }

    return isAfter(date, dateToCompare);
};

/**
 * Converts a unix time to a date object
 * @param {number} seconds unix time in seconds
 * @returns {Date} date object
 */
export const parseUnixTime = (seconds) => {
    return fromUnixTime(seconds);
};

/**
 * Finds the diference between two dates based on the period
 * @param {Date} date starting date
 * @param {Date} dateToCompare comparing date
 * @param {string} period tome period, days, hours, minutes
 * @returns {number} number of units between two dates
 */
export const diff = (date, dateToCompare, period) => {
    if (period === 'days') {
        return differenceInDays(date, dateToCompare);
    } else if (period === 'hours') {
        return differenceInHours(date, dateToCompare);
    } else if (period === 'minutes') {
        return differenceInMinutes(date, dateToCompare);
    } else {
        return 0;
    }
};

/**
 * Clones the given date to a new object
 * @param {Date} date date object to clone
 * @returns {Date} new date object
 */
export const clone = (date) => {
    return new Date(date.getTime());
};

/**
 * Adds a duration to a date
 * @param {Date} date date object to add duration to
 * @param {number} duration length of time to add
 * @param {string} period type of duration to add; acceptable are: days, months, hours
 * @returns {Date} new date object
 */
export const add = (date, duration, period) => {
    if (period === 'days') {
        return addDays(date, duration);
    } else if (period === 'months') {
        return addMonths(date, duration);
    } else if (period === 'hours') {
        return addHours(date, duration);
    } else if (period === 'weeks') {
        return addWeeks(date, duration);
    } else {
        return 0;
    }
};

/**
 * Gets the number of days in a month
 * @param {Date} [date] date object to get number of days from
 * @returns {number} number of days in the given month
 */
export const daysInMonth = (date) => {
    if (!date) {
        date = new Date();
    }

    return getDaysInMonth(date);
};

/**
 * Gets the date for the start of the week
 * @param {Date} [date] date object to get number of days from
 * @returns {Date} ISO date the week starts on
 */
export const startOfWeek = (date) => {
    if (!date) {
        date = new Date();
    } else if (typeof date === 'string') {
        date = parseDateTime(date);
    }

    return startOfISOWeek(date);
};

/**
 * Gets the day of the week, 7=Sunday, 1=Monday, etc.
 * @param {Date} [date] date object to get day of week from
 * @returns {number} day of week represented as a number
 */
export const getWeekday = (date) => {
    if (!date) {
        date = new Date();
    } else if (typeof date === 'string') {
        date = parseDateTime(date);
    }

    return getDay(date);
};

/**
 * Gets the day of the week, 7=Sunday, 1=Monday, etc.
 * @param {Date} [date] date object to get day of week from
 * @returns {number} day of week represented as a number
 */
export const getISOWeekday = (date) => {
    if (!date) {
        date = new Date();
    } else if (typeof date === 'string') {
        date = parseDateTime(date);
    }

    return getISODay(date);
};

/**
 * Sets the ISO date based on the week in year number
 * @param {Date} [date] date object to get the week from
 * @param {number} week  week number to transform
 * @returns {Date} updated date object
 */
export const setiSOWeek = (date, week) => {
    if (!date) {
        date = new Date();
    }

    return setISOWeek(date, week);
};

/**
 * Gets the week of the given date
 * @param {Date} [date] date object to get the week from
 * @returns {number} week represented as a number
 */
export const iSOWeek = (date) => {
    if (!date) {
        date = new Date();
    }

    return getISOWeek(date);
};

/**
 * Sets the local date/time based on the week in year number
 * @param {Date} [date] date object to get the week from
 * @param {number} week  week number to transform
 * @returns {Date} updated date object
 */
export const setLocalWeek = (date, week) => {
    if (!date) {
        date = new Date();
    }

    return setWeek(date, week);
}

/**
 * Gets the week of the given date
 * @param {Date} [date] date object to get the week from
 * @returns {number} week represented as a number
 */
export const week = (date) => {
    if (!date) {
        date = new Date();
    }

    return getWeek(date);
};

/**
 * Gets the month for the given date
 * @param {Date} [date] date object to get the week from
 * @returns {number} month represented as a number
 */
export const month = (date) => {
    if (!date) {
        date = new Date();
    }

    return getMonth(date);
};

/**
 * Gets the month for the given date
 * @param {Date} [date] date object to check if date is today
 * @returns {boolean} true is date matches current date
 */
export const today = (date) => {
    if (!date) {
        date = new Date();
    }

    return isToday(date);
};

/**
 * Sets the day of the month
 * @param {Date} date date object to set day of month for
 * @param {number} dayOfMonth the day of the month to set
 * @returns {Date} new date object
 */
export const setDayOfMonth = (date, dayOfMonth) => {
    return setDate(date, dayOfMonth);
};

/**
 * Gets the day of the month
 * @param {Date} [date] date object to get the day of month for
 * @returns {Date} new date object
 */
export const getDayOfMonth = (date) => {
    if (!date) {
        date = new Date();
    }

    return getDate(date);
};

/**
 * Gets the number of weeks
 * @param {Date} date date object to get the weeks from
 * @returns {number} number of weeks
 */
export const iSOWeeksInYear = (date) => {
    if (!date) {
        date = new Date();
    }

    return getISOWeeksInYear(date);
};

/**
 * Gets the number of weeks
 * @param {Date} date date object to get the weeks from
 * @returns {number} number of weeks
 */
export const weeksInYear = (date) => {
    if (!date) {
        date = new Date();
    }

    return getISOWeeksInYear(date);
};

/**
 * Gets the timestamp in seconds
 * @param {Date} [date] date object to set day of month for
 * @returns {number} date in seconds } from January 1, 1970
 */
export const getUnix = (date) => {
    if (!date) {
        date = new Date();
    }

    return getUnixTime(date);
};

/**
 * Get the week of the month for the given date
 * @param {Date} [date] date object to get the week of the month for
 * @returns {number} numeric representation of the month's week (i.e. 2)
 */
export const getWeekInMonth = (date) => {
    if (!date) {
        date = new Date();
    } else if (typeof date === 'string') {
        date = parseDateTime(date);
    }

    return getWeekOfMonth(date);
};
