import React, { useState, useEffect, useCallback, Suspense, ReactComponentElement } from 'react';
import { Container, Row, Col, ListGroup, Button, Modal, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcAmex, faCcMastercard, faCcDiscover } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard, faTrash, faPlusCircle, faCoins, faLocationArrow, faRedo, faExchangeAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { SetupIntent } from '@stripe/react-stripe-js';
import { getApplicationDetails } from '../../libs/Api/application';
import { getCustomerBillingInfo, getSubscriptionInfo, saveNewPaymentMethodCustomer, setupNewPaymentIntent, savePaymentMethod, deletePaymentMethod, saveSubscription, cancelSubscription, getCountryList } from '../../libs/Api/billing';
import { Loading } from '../../../components/Loading';
import { LoaderButton } from '../../../components/LoaderButton'
import { isNumber, adjustHeader } from '../../../assets/scripts/utilsLib';
import { parseUnixTime, formatDate, diff } from '../../../assets/scripts/dateLib';
import { useRouter } from '../../../assets/scripts/hooksLib';
// import { onError } from '../../../assets/scripts/errorLib';
import config from '../../../config';
import '../../../assets/styles/billing.scss';

const NewBillingInfoModal = React.lazy(() => import('../../components/Billing/NewBillingInfoModal'));
const EditBillingInfoModal = React.lazy(() => import('../../components/Billing/EditBillingInfoModal'));
const PricingTable = React.lazy(() => import('../../components/NewApp/PricingTable'));
const ErrorModal = React.lazy(() => import('../../components/ErrorMessage'));

/**
 * Application Billing page
 * @returns {HTMLElement} html for billing block
 */
export default function Billing() {
    const router = useRouter();
    const [pageLoading, setPageLoading] = useState(true); // used to manage state of page
    const [isLoading, setIsLoading] = useState(false); // used to manage state of buttons
    const [isCardDeleting, setIsCardDeleting] = useState(false); // used to manage state of buttons
    const [isPlanDeleting, setIsPlanDeleting] = useState(false); // used to manage state of buttons
    const [disabled, setDisabled] = useState(false); // used to disable/enable Cancel Plan button
    const [app, setApp] = useState(null);
    const [user, setUser] = useState(null);
    const [stripe, setStripe] = useState(null);
    const [isCardComplete, setIsCardComplete] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showChoices, setShowChoices] = useState(false);
    const [canEdit, setCanEdit] = useState(true);
    const [canDelete, setCanDelete] = useState(true);
    const [subscription, setSubscription] = useState();
    const [customer, setCustomer] = useState({
        id: '',
        name: '',
        email: '',
        address: {
            line1: '',
            city: '',
            state: '',
            postal_code: '',
            country: ''
        },
        card: {
            brand: '',
            exp_month: '',
            exp_year: '',
            last4: ''
        }
    });
    const defaultCustomer = {
        name: customer.name || '',
        email: customer.email || '',
        address_line1: '',
        address_city: '',
        address_state: '',
        address_postal: '',
        address_country: '',
        brand: '',
        exp_month: '',
        exp_year: '',
        last4: '',
        termsAccepted: false
    };
    const [billingCustomer, setBillingCustomer] = useState(defaultCustomer);
    const subscr = app && config.subscriptionTypes[app.subscriptionType > 0 ? app.subscriptionType - 1 : 0]; // validate the subscriptionType is between 1 and 4
    const handleCloseNew = () => {
        setCustomer(c => {
            delete c.client_secret; // clean up left over secret
            return { ...c }
        });
        setCanDelete(false);
        setCanEdit(false);
        setShowNew(false);
    };
    const handleCloseEdit = () => { setBillingCustomer(defaultCustomer); setShowEdit(false); };
    const handleShowEdit = () => {
        setBillingCustomer({
            name: customer.name || '',
            email: customer.email || '',
            address_line1: customer.address.line1,
            address_city: customer.address.city,
            address_state: customer.address.state,
            address_postal: customer.address.postal_code,
            address_country: customer.address.country,
            brand: customer.card.brand,
            exp_month: customer.card.exp_month,
            exp_year: customer.card.exp_year,
            last4: customer.card.last4,
            termsAccepted: false
        });
        setShowEdit(true);
    };
    const handleCloseChoices = () => {
        setApp(app => {
            return { ...app, ['subscriptionType']: origSubscription }
        });

        setShowChoices(false);
    };
    const handleShowChoices = () => setShowChoices(true);
    const [countryList, setCountryList] = useState(null);
    const [origSubscription, setOrigSubscription] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);

    /**
     * @typedef {{
     * onkeyup: number
     * }} KeyEvent
     */

    /**
     * Sets the Error Modal Window with error message and context
     * @param {string} title error message window title
     * @param {string} message error messsage
     * @param {[string]} level (optional) level of severity, e.g. danger, warning, info, etc.
     * @param {[string]} buttonTxt (optional) text label on button
     * @returns {void}
     */
    const setErrorOccurred = (title, message, level='danger', buttonTxt='OK') => {
        setErrorMessage({
            title: title,
            message: message,
            level: level,
            buttonText: buttonTxt
        });
        setShowErrorModal(true);
    };

    /**
     * Switches the Error modal window visibility to true or false
     * @returns {void}
     */
    const showErrorMessageModal = () => {
        if (showErrorModal) {
            setShowErrorModal(false);
            setErrorMessage({});
        } else {
            setShowErrorModal(true);
        }
    };

    /******************************************************************/
    /* Pre-Loading functions for setting up a new payment method      */
    /******************************************************************/
    /**
     * Shows the modal for creating a new Payment Method and assign's the required client secret
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const handleShowNew = useCallback(async (e) => {
        if (e) e.preventDefault();

        setIsLoading(true);
        setCanDelete(false);
        setCanEdit(false);

        try {
            const { status, message, body, code } = await setupNewPaymentIntent(router.query.id)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response.data.message || err.message,
                        code: err.response.data.code
                    };
                });

            if (status !== 'OK') {
                if (code === 'EMISSINGCUSTOMERBILLINGINFO') {
                    console.log(message);
                    setShowChoices(false);
                    setShowNew(true);
                } else {
                    setErrorOccurred('Missing Customer Billing Info', message);
                }
            } else {
                setCustomer(customer => {
                    return { ...customer, 'client_secret': body.client_secret }
                });
                setShowChoices(false);
                setShowNew(true);
            }
        } catch (e) {
            // TODO: possibly get a 401 here if the page is idle too long, should we reload
            setErrorOccurred('An Unexpected Error Occurred', e);
        } finally {
            setIsLoading(false);
        }
    }, [router, setIsLoading]);

    /******************************************************/
    /* Load page details                                  */
    /******************************************************/
    // load application info
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                adjustHeader();

                const { status, message, body } = await getApplicationDetails(router.query.id)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    setErrorOccurred('Error Loading Application Info', message);
                } else {
                    setApp(body);
                    setDisabled(!body.Enabled);

                    // set whether the current user is allowed to delete and/or edit this info
                    if (user) {
                        const isOwner = body.applicationMembers.find(a => {
                            return a.UserId === user.username;
                        });

                        setCanDelete(body.Enabled && isOwner);
                        setCanEdit(body.Enabled && isOwner);
                    }
                }
            } catch (e) {
                // console.error(e);
                if (e.response && e.response.status === 404) {
                    //console.log(e.response);
                    // router.push('/apps');
                    router.history('/apps');
                } else if (e === 'not authenticated' || e.response && e.response.status === 401) {
                    // router.history(`/login?redirect=${router.pathname}${router.query}`);
                    window.location.href = `../login.html?redirect=${router.pathname}${router.query}`;
                } else {
                    setErrorOccurred('An Unexpected Error Occurred', e);
                }
            }
        };

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [router, setApp, setDisabled]);

    // load customer billing info
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                const cognitoUser = await fetchUserAttributes()
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.message
                        };
                    });
                // console.log(cognitoUser);
                setUser({
                    username: cognitoUser.username,
                    email: cognitoUser.email,
                    name: cognitoUser.name
                });
                // setup the default customer state
                setCustomer(c => {
                    return { ...c, email: cognitoUser.email, username: cognitoUser.username }
                });

                // get the customer billing info for this application
                const { status, message, body } = await getCustomerBillingInfo(router.query.id)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    setErrorOccurred('Error Loading Customer Billing Info', message);
                } else {
                    if (body && (body.customer || body.billing_details)) {
                        setCustomer(c => {
                            return hydrateCustomer(c, body);
                        });
                    }

                    // check if there are any warnings to review
                    if (body.warnings && body.warnings.length > 0) {
                        for (var i = 0; i < body.warnings.length; i++) {
                            if (body.warnings[`${i}`] === 'WMISSINGSUBSCRIPTIONPAYMENTMETHOD') {
                                handleShowNew();
                                break;
                            } else if (body.warnings[`${i}`] === 'WMISSINGSUBSCRIPTION') {
                                setShowChoices(true);
                                break;
                            } else if (body.warnings[`${i}`] === 'WMISSINGCUSTOMERBILLINGINFO') {
                                break;
                            }
                        }
                    }
                }
            } catch (e) {
                if (e.response && e.response.status === 404) {
                    // router.push('/apps');
                    setErrorOccurred('Unauthorized', e);
                } else if (e.response && e.response.status === 400) {
                    if (e.response.data) {
                        const code = e.response.data.code;
                        // if (code === 'EMISSINGSUBSCRIPTIONPAYMENTMETHOD') {
                        //   console.log(e.response.data.message);
                        //   setShowNew(true);
                        // } else if (code === 'EMISSINGSUBSCRIPTION') {
                        //   console.log(e.response.data.message);
                        //   setShowChoices(true);
                        // } else if (code === 'EMISSINGCUSTOMERBILLINGINFO') {
                        if (code === 'EPAYMENTMETHODMISSING') {
                            handleShowNew();
                        } else if (code === 'EMISSINGCUSTOMERBILLINGINFO') {
                            console.log(e.response.data.message);
                            setShowChoices(true);
                        } else if (code === 'STRIPEINVALIDREQUESTERROR') {
                            setErrorOccurred(e.response.data.message, e);
                        }
                        // onError(e.response.data.message);
                    } else {
                        setErrorOccurred('Bad Request', e);
                    }
                } else if (e === 'not authenticated' || e.response && e.response.status === 401) {
                    // router.history(`/login?redirect=${router.pathname}${router.query}`);
                    window.location.href = `../login.html?redirect=${router.pathname}${router.query}`;
                } else {
                    setErrorOccurred('An Unexpected Error Occurred', e);
                }
            } finally {
                setPageLoading(false);
            }
        };

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [router, setPageLoading, handleShowNew]);

    // load subscription info
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                // get the subscription info for this application
                const { status, message, body } = await getSubscriptionInfo(router.query.id)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    setErrorOccurred('Error Loading Subscription Info', message);
                } else {
                    // console.log(body);
                    setSubscription(body);
                }
            } catch (e) {
                if (e.response && e.response.status === 404) {
                    // router.push('/apps');
                    router.history('/apps');
                } else if (e.response && e.response.status === 400) {
                    if (e.response.data) {
                        const code = e.response.data.code;
                        if (code === 'ENOSUBSCRIPTIONFOUND') {
                            setShowChoices(true);
                        } else if (code === 'ECUSTOMERSUBSCRIPTION-ENOSUBSCRIPTIONFOUND') {
                            // do nothing
                        }
                    } else {
                        setErrorOccurred('Bad Reqeust', e);
                    }
                } else if (e === 'not authenticated' || e.response && e.response.status === 401) {
                    // router.history(`/login?redirect=${router.pathname}${router.query}`);
                    window.location.href = `../login.html?redirect=${router.pathname}${router.query}`;
                } else {
                    setErrorOccurred('An Unexpected Error Occurred', e);
                }
            } finally {
                // setPageLoading(false);
            }
        };

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [router, setSubscription]);

    // load stripe country list
    useEffect(() => {
        let isMounted = true;

        const stripeCountryList = async () => {
            try {
                const { status, message, body } = await getCountryList()
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    setErrorOccurred('Error Loading Country List', message);
                } else {
                    if (body) {
                        setCountryList(body);
                    }
                }
            } catch (err) {
                setErrorOccurred('An Unexpected Error Occurred', err);
            } finally {
                // setIsLoading(false);
            }
        }

        if (isMounted) {
            stripeCountryList()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [setCountryList, setIsLoading]);

    // load stripe
    useEffect(() => {
        // dynamically add the Stripe JS to the page
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/';
        script.async = true;
        script.setAttribute('id', 'stripe-js');
        document.body.appendChild(script);

        if (window.Stripe) {
            setStripe(window.Stripe(config.STRIPE_KEY));
        } else {
            document.querySelector('#stripe-js').addEventListener('load', () => {
                // Create Stripe instance once Stripe.js loads
                setStripe(window.Stripe(config.STRIPE_KEY));
            });
        }

        // clean up the Stripe JS script when we leave
        return () => {
            document.body.removeChild(script);
        };
    }, [setStripe]);
    /******************************************************/

    /******************************************************/
    /* Functions for setting up a new payment method      */
    /******************************************************/
    /**
     * Checks the new credit card form is complete
     * @returns {boolean} true|false
     */
    const validateNewCardForm = () => {
        return (
            billingCustomer.name !== '' &&
            billingCustomer.address_line1 !== '' &&
            billingCustomer.city !== '' &&
            billingCustomer.postal !== '' &&
            billingCustomer.country !== '' &&
            billingCustomer.termsAccepted &&
            isCardComplete
        );
    };

    /**
     * Creates and saves a new payment method and replaces the current one (if necessary) for this application
     * @param {SetupIntent} setupIntent stripe SetupIntent object
     * @param {object} newcust customer object to create
     * @returns {void}
     */
    const onPaymentMethodCreateSubmit = async (setupIntent, newcust) => {
        setIsLoading(true);

        try {
            // save the customer id to the new app
            setApp(app => {
                return { ...app, 'subscriptionCustomerId': newcust ? newcust.id : customer.id || app.subscriptionCustomerId, 'subscriptionPaymentId': setupIntent.payment_method }
            });

            if (!app.subscriptionType) {
                alert('Plese select a Subscription Plan before entering your Payment information.');
            } else {

                // save the new payment method to the database
                await saveNewPaymentMethodCustomer(router.query.id,
                    {
                        paymentId: setupIntent.payment_method,
                        subscriptionType: app.subscriptionType
                    },
                    newcust || customer,
                    billingCustomer
                )
                    .catch(err => {
                        console.error(err.message);
                    });

                const { status, message, body } = await getCustomerBillingInfo(router.query.id)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.message
                        };
                    });

                if (status !== 'OK') {
                    setErrorOccurred('Error Creating Payment Method', message);
                } else {
                    setCustomer(hydrateCustomer(customer, body));
                    setDisabled(false); // enable the button after the CC is added

                    // set whether the current user is allowed to delete and/or edit this info
                    if (user) {
                        const isOwner = app.applicationMembers.find(a => {
                            return a.UserId === user.username;
                        });

                        setCanDelete(app.Enabled && isOwner);
                        setCanEdit(app.Enabled && isOwner);
                    }
                }

                // close the modal window as we're all set
                setShowNew(false);

                // TODO: display new billing cycle info and billing info
            }
        } catch (e) {
            setErrorOccurred('An Unexpected Error Occurred', e);
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Builds the credit card form for creating a new customer
     * @returns {ReactComponentElement} stripe element form
     */
    const renderNewBillingForm = () => {
        return app && stripe && showNew &&
            <Suspense fallback={<Loading pageLoading={true} />}>
                <NewBillingInfoModal
                    stripe={stripe}
                    modal={{
                        show: showNew,
                        handleClose: handleCloseNew
                    }}
                    icon={renderCcIcon(billingCustomer.brand)}
                    app={app}
                    isLoading={isLoading}
                    customer={customer}
                    setCustomer={setCustomer}
                    billingCustomer={billingCustomer}
                    setBillingCustomer={setBillingCustomer}
                    onChangeBillingCustomer={onChangeBillingCustomer}
                    countryList={countryList}
                    onValidateForm={validateNewCardForm}
                    setIsCardComplete={setIsCardComplete}
                    onSubmit={onPaymentMethodCreateSubmit}
                    onCancel={handleCloseEdit}
                    user={user}
                />
            </Suspense>
        ;
    };
    /******************************************************/

    /******************************************************/
    /* Functions for modifying an existing payment method */
    /******************************************************/
    /**
     * Checks the credit card modification form is complete
     * @returns {boolean} true|false
     */
    const validateForm = () => {
        return (
            billingCustomer.name !== '' &&
            billingCustomer.address_line1 !== '' &&
            billingCustomer.address_city !== '' &&
            billingCustomer.address_postal !== '' &&
            billingCustomer.address_country !== '' &&
            isNumber(billingCustomer.exp_month) &&
            isNumber(billingCustomer.exp_year)
        );
    };

    /**
     * Modifies the current payment method
     * @param {object} billingDetails collection of billing details that stripe accepts
     * @param {Function} [cleanup] function to run any cleanup before unmouting a component
     * @returns {object} collection of updated stripe payment info
     */
    const onPaymentUpdateSubmit = async (billingDetails, cleanup) => {
        setIsLoading(true);

        try {
            const { status, message, body } = await savePaymentMethod(router.query.id, billingDetails)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.message
                    };
                });

            if (status !== 'OK') {
                setErrorOccurred('Error Updating Payment Method', message);
            } else {
                setCustomer(c => {
                    return hydrateCustomer(c, body)
                });

                // set whether the current user is allowed to delete and/or edit this info
                if (user) {
                    const isOwner = app.applicationMembers.find(a => {
                        return a.UserId === user.username;
                    });

                    setCanDelete(app.Enabled && isOwner);
                    setCanEdit(app.Enabled && isOwner);
                }

                // run any remaining/outstanding functions before the component unmounts
                if (cleanup) {
                    cleanup();
                }

                handleCloseEdit(); // *** seems to throw a react error due to 'unmounted component' ***
            }
        } catch (e) {
            setErrorOccurred('An Unexpected Error Occurred', e);
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Builds the credit card edit form for existing customer
     * @returns {ReactComponentElement} stripe element form
     */
    const renderEditBillingForm = () => {
        return app && stripe && showEdit &&
            <Suspense fallback={<Loading pageLoading={true} />}>
                <EditBillingInfoModal
                    stripe={stripe}
                    modal={{
                        show: showEdit,
                        handleClose: handleCloseEdit
                    }}
                    icon={renderCcIcon(customer.card.brand)}
                    app={app}
                    isLoading={isLoading}
                    card={{
                        exp_month: customer.card.exp_month,
                        exp_year: customer.card.exp_year,
                        brand: customer.card.brand,
                        last4: customer.card.last4
                    }}
                    billingCustomer={billingCustomer}
                    onChangeBillingCustomer={onChangeBillingCustomer}
                    countryList={countryList}
                    onValidateForm={validateForm}
                    onSubmit={onPaymentUpdateSubmit}
                />
            </Suspense>
        ;
    };
    /******************************************************/

    /******************************************************/
    /* Functions for deleting the existing card & subscription */
    /******************************************************/
    /**
     * Removes the current payment method from the stripe customer and this application
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const handleDeleteCard = async (e) => {
        e.preventDefault();

        const approved = window.confirm('Do you want to DELETE this credit Card?\n\nWithout valid payment information your Application will be disabled.\n\nClick OK to continue.');

        if (approved) {
            try {
                setIsCardDeleting(true);

                const { status, message } = await deletePaymentMethod(router.query.id)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.message
                        };
                    });

                if (status !== 'OK') {
                    setErrorOccurred('Error Deleting Card', message);
                } else {
                    // reset card info as it was just removed
                    setCustomer(c => {
                        return {
                            ...c, card: {
                                exp_year: '',
                                exp_month: '',
                                brand: '',
                                last4: ''
                            }
                        }
                    });
                    handleShowNew();
                }
            } catch (err) {
                setErrorOccurred('An Unexpected Error Occurred', err);
            } finally {
                setIsCardDeleting(false);
            }
        }
    };
    /******************************************************/

    /******************************************************/
    /* Functions for updating the subscription            */
    /******************************************************/
    /**
     * Checks that a valid subscription type was chosen
     * @returns {boolean} true|false
     */
    const validateSubscriptionForm = () => {
        return app.subscriptionType > 0 && app.subscriptionType <= config.subscriptionTypes.reduce((prev, current) => prev.y > current.y ? prev : current).id;
    };

    /**
     * Track the subscription choice in the application's state
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const onSubscriptionChange = (e) => {
        if (e.target.type === 'radio') {
            if (!isNaN(e.target.value)) {
                const field = 'subscriptionType',
                    val = e.target.value;

                if (!origSubscription) {
                    setOrigSubscription(app.subscriptionType);
                }

                setApp(app => {
                    return { ...app, [field]: val }
                });
            }
        }
    };

    /**
     * Saves a new subscription choice for this application
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const onSaveSubscriptionSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        try {
            const { status, message, body } = await saveSubscription(app.applicationId, app.subscriptionType)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.message
                    };
                });

            if (status !== 'OK') {
                setErrorOccurred('Error Saving Subscription', message);
            } else {
                setApp(body.application);
                setDisabled(!body.application.Enabled);
                setShowChoices(false);
                setSubscription(body.subscription);
                setOrigSubscription(body.application.subscriptionType);

                // set whether the current user is allowed to delete and/or edit this info
                if (user) {
                    const isOwner = body.application.applicationMembers.find(a => {
                        return a.UserId === user.username;
                    });

                    setCanDelete(body.application.Enabled && isOwner);
                    setCanEdit(body.application.Enabled && isOwner);
                }
            }
        } catch (e) {
            setErrorOccurred('An Unexpected Error Occurred', e);
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Organize and navigate to the credit card billing details collection page
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const goToPaymentMethod = (e) => {
        handleShowNew(e);
    };

    /**
     * Builds and displays the appropriate button based on the applications' current payment method state
     * @returns {ReactComponentElement} html button element
     */
    const nextButton = () => {
        // if there is not a payment method in place, move to the new credit card modal window
        if (!app.subscriptionPaymentId) {
            return (
                <LoaderButton
                    variant='success'
                    isLoading={isLoading}
                    disabled={!validateSubscriptionForm()}
                    onClick={goToPaymentMethod}
                >
                    Save &amp; Next &gt;
                </LoaderButton>
            );
        } else {
            return (
                <LoaderButton
                    variant='success'
                    isLoading={isLoading}
                    disabled={!validateSubscriptionForm()}
                    onClick={onSaveSubscriptionSubmit}
                >
                    Save
                </LoaderButton>
            );
        }
    };

    /**
     * Builds and displays the Add, Change, and Delete buttons as appropriate
     * @returns {ReactComponentElement|void} html button elements or nothing
     */
    const changeOrAddSubscriptionButton = () => {
        if (app) {
            if (app.subscriptionId && app.subscriptionId.indexOf('sub_') > -1) {
                return (
                    <ButtonToolbar>
                        <ButtonGroup>
                            <Button
                                variant='warning'
                                disabled={disabled}
                                onClick={handleShowChoices}
                                title='Change the current subscription plan'
                            >
                                <FontAwesomeIcon icon={faExchangeAlt} />&nbsp;Change Plan
                            </Button>
                            <LoaderButton
                                variant='danger'
                                disabled={disabled}
                                onClick={onClickCancelPlan}
                                title='Cancel This Plan?'
                                isLoading={isPlanDeleting}
                                aria-controls='remove-subscription-plan'
                            >
                                <FontAwesomeIcon icon={faTrash} />&nbsp;Cancel Plan
                            </LoaderButton>
                        </ButtonGroup>
                    </ButtonToolbar>
                );
            } else {
                return (
                    <ButtonToolbar>
                        <Button
                            variant='success'
                            onClick={handleShowChoices}
                            title='Add a new Plan'
                        >
                            <FontAwesomeIcon icon={faPlusCircle} />&nbsp;Add&nbsp;
                            <FontAwesomeIcon icon={faLocationArrow} />
                        </Button>
                    </ButtonToolbar>
                );
            }
        }
    };

    /**
     * Builds and displays the subscription choice pricing tables
     * @returns {ReactComponentElement} html displaying pricing selection
     */
    const renderSubscriptionChoices = () => {
        return app && showChoices &&
            <Modal
                size='lg'
                show={showChoices}
                onHide={handleCloseChoices}
                backdrop='static'
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faLocationArrow} />&nbsp;Select Your Subscription Plan
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Suspense fallback={<Loading pageLoading={true} />}>
                        <PricingTable subscription={app.subscriptionType} onclick={onSubscriptionChange} freeOnly={!app.subscriptionCustomerId} />
                    </Suspense>
                    <div>
                        * In the event your are downgrading your subscription, existing scheduled social media posts will be disabled.
                    </div>
                    <br />
                    <div>
                        ** By clicking &apos;Save&apos; you are agreeing to Stitchz.net Terms &amp;
                        Conditions and acknowledging that Stitchz.net can charge
                        the Credit Card on file the selected Subscription Plan on a
                        monthly basis.
                        <br />
                        <small>
                            (Note: the plan will begin immediately and your credit card
                            will be charged accordingly)
                        </small>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar
                        className='justify-content-between'
                        aria-label='Toolbar with Button groups'
                    >
                        <ButtonGroup>
                            {nextButton()}
                            <Button
                                variant='warning'
                                onClick={handleCloseChoices}
                            >
                                Cancel
                            </Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Modal.Footer>
            </Modal>
        ;
    };
    /******************************************************/

    /******************************************************/
    /* Functions for cancelling an existing subscription  */
    /******************************************************/
    /**
     * Initiate the cancel subscription request with confirmation box and refreshes the state
     * @param {MouseEvent} e button click event
     * @returns {void}
     */
    const onClickCancelPlan = async (e) => {
        e.preventDefault();

        const approved = window.confirm('BY CANCELLING YOUR PLAN YOU ARE EFFECTIVELY STOPPING ALL FUNCTIONALITY OF THIS APPLICATION.\n\nDO YOU WISH TO CONTINUE?');

        if (approved) {
            setIsPlanDeleting(true);

            try {
                const { status, message } = await cancelSubscription(app.applicationId)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.message
                        };
                    });

                if (status !== 'OK') {
                    setErrorOccurred('Error Cancelling Plan', message);
                } else {
                    router.history(`/${app.applicationId}`);
                }
            } catch (err) {
                setErrorOccurred('An Unexpected Error Occurred', err);
            }
        }
    };
    /******************************************************/

    /******************************************************/
    /* Shared & Helper functions                          */
    /******************************************************/
    /**
     * Populate the customer object in a consistent way
     * @param {object} prev previous customer object details
     * @param {object} next next customer object details
     * @returns {object} returns the new customer object
     */
    const hydrateCustomer = (prev, next) => {
        // console.log(next);
        return {
            ...prev,
            id: next.object === 'payment_method' ? next.customer : next.id,
            name: next.object === 'payment_method' ? next.billing_details.name : next.name,
            email: next.object === 'customer' ? next.email || prev.email : prev.email,
            address: {
                line1: next.object === 'payment_method' ? next.billing_details.address.line1 : next.address.line1,
                city: next.object === 'payment_method' ? next.billing_details.address.city : next.address.city,
                state: next.object === 'payment_method' ? next.billing_details.address.state : next.address.state,
                postal: next.object === 'payment_method' ? next.billing_details.address.postal_code : next.address.postal_code,
                country: next.object === 'payment_method' ? next.billing_details.address.country : next.address.country
            },
            card: {
                brand: next.object === 'payment_method' ? next.card.brand : '',
                exp_month: next.object === 'payment_method' ? next.card.exp_month : '',
                exp_year: next.object === 'payment_method' ? next.card.exp_year : '',
                last4: next.object === 'payment_method' ? next.card.last4 : ''
            }
            // token: customer.token,
            // username: customer.username
        }
    };

    /**
     * Handles the state of the billingCustomer object
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onChangeBillingCustomer = (e) => {
        const field = e.target.id;
        let val = e.target.value;

        if (e.target.type === 'checkbox') {
            val = e.target.checked;
        }

        if (field === 'exp_month' && val === 'MM') {
            val = customer.exp_month;
        }

        if (field === 'exp_year' && val === 'YY') {
            val = customer.exp_year;
        }

        setBillingCustomer(billingCustomer => {
            return { ...billingCustomer, [field]: val }
        });
    };

    /**
     * Returns a credit card icon
     * @param {string} brand credit card brand name
     * @returns {ReactComponentElement} font awesome icon element
     */
    const renderCcIcon = (brand) => {
        if (brand === 'visa') {
            return <FontAwesomeIcon icon={faCcVisa} title='Visa' />;
        } else if (brand === 'amex') {
            return <FontAwesomeIcon icon={faCcAmex} title='American Express' />;
        } else if (brand === 'mastercard') {
            return <FontAwesomeIcon icon={faCcMastercard} title='Mastercard' />;
        } else if (brand === 'discover') {
            return <FontAwesomeIcon icon={faCcDiscover} title='Discover' />;
        } else {
            return <FontAwesomeIcon icon={faCreditCard} title='Credit Card' />;
        }
    };
    /******************************************************/

    return (
        <div className='Billing'>
            {pageLoading ?
                <Loading pageLoading={pageLoading} /> :
                <Container>
                    {renderNewBillingForm()}
                    {renderEditBillingForm()}
                    {renderSubscriptionChoices()}
                    <Row>
                        <Col md={4}>
                            <Row>
                                <Col>
                                    <h3><FontAwesomeIcon icon={faRedo} />&nbsp;Billing Cycle</h3>
                                </Col>
                            </Row>
                            {subscription && Object.keys(subscription).length > 1 ?
                                <>
                                    <Row>
                                        <Col>
                                            Billing Period from {subscription && formatDate(parseUnixTime(subscription.current_period_start), 'MMM do')} to {subscription && formatDate(parseUnixTime(subscription.current_period_end), 'MMM do')}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            Monthly charge ${subscription && subscription.plan.amount / 100} / {subscription && subscription.plan.interval}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            Next billing cycle begins in {subscription && diff(parseUnixTime(subscription.current_period_end), new Date(), 'days')} days
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='smaller-x2 padTop'>
                                            * Credit card details are securely transmitted to our trusted third party Stripe.com for processing. No details are ever stored with us (Stitchz.net). Please refer to Stripe.com&apos;s <a href='https://stripe.com/privacy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a> for more info.
                                        </Col>
                                    </Row>
                                </>
                                :
                                <Row>
                                    <Col>
                                        Free Plan
                                    </Col>
                                </Row>
                            }
                        </Col>
                        <Col md={4}>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <h3><FontAwesomeIcon icon={faCoins} />&nbsp;Billing Info</h3>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} className='smaller-label'>Card Holder</Col>
                                        <Col md={8}>{customer && customer.name}</Col>
                                    </Row>
                                    <Row>
                                        <Col>&nbsp;</Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} className='smaller-label'>Card Exp</Col>
                                        <Col md={8}>{customer && customer.card.exp_month} / {customer && customer.card.exp_year}</Col>
                                    </Row>
                                    <Row>
                                        <Col>&nbsp;</Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} className='smaller-label'>Card Last 4 Digits</Col>
                                        <Col md={8}>.... {customer && customer.card.last4} &nbsp; {renderCcIcon(customer.card.brand)}</Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ButtonToolbar>
                                                <ButtonGroup>
                                                    <Button
                                                        disabled={!canEdit}
                                                        onClick={handleShowEdit}
                                                        title='Edit the current Credit Card'
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />&nbsp;Edit&nbsp;
                                                        {renderCcIcon('')}
                                                    </Button>
                                                    <LoaderButton size='sm'
                                                        variant='success'
                                                        onClick={handleShowNew}
                                                        isLoading={isLoading}
                                                        aria-controls='add-new-credit-card-info'
                                                        aria-expanded={showNew}
                                                        title='Add or Replace a Credit Card'
                                                    >
                                                        <FontAwesomeIcon icon={faPlusCircle} />&nbsp;Add New&nbsp;
                                                        {renderCcIcon('')}
                                                    </LoaderButton>
                                                    <LoaderButton
                                                        variant='danger'
                                                        isLoading={isCardDeleting}
                                                        disabled={!canDelete}
                                                        onClick={handleDeleteCard}
                                                        aria-controls='remove-credit-card-info'
                                                        title='Delete the Credit Card'
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />&nbsp;Delete&nbsp;
                                                        {renderCcIcon('')}
                                                    </LoaderButton>
                                                </ButtonGroup>
                                            </ButtonToolbar>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <Row>
                                <Col>
                                    <h3><FontAwesomeIcon icon={faLocationArrow} />&nbsp;Subscription Plan</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ListGroup as='ul' bsPrefix='list-group pricing-table'>
                                        <ListGroup.Item as='li' bsPrefix='list-group-item title'>{subscr && subscr.name} Plan</ListGroup.Item>
                                        <ListGroup.Item as='li' bsPrefix='list-group-item price'>${subscr && subscr.price}/month</ListGroup.Item>
                                        <ListGroup.Item as='li' bsPrefix='list-group-item bullet-item'>{subscr && subscr.maxMonthlyShareLimit} shares per month</ListGroup.Item>
                                        <ListGroup.Item as='li' bsPrefix='list-group-item bullet-item'>{subscr && subscr.maxSocialProfileLimit} social profiles</ListGroup.Item>
                                        <ListGroup.Item as='li' bsPrefix='list-group-item bullet-item'>{subscr && subscr.maxDeveloperLimit} members</ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {changeOrAddSubscriptionButton()}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>&nbsp;</Col>
                    </Row>
                </Container>
            }
            { errorMessage ?
                <Suspense fallback={<Loading pageLoading={true} />}>
                    <ErrorModal message={errorMessage} show={showErrorModal} handleClose={showErrorMessageModal} />
                </Suspense>
                : ''
            }
        </div>
    );
}
